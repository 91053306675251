<template>
   <div class="audit-detail-wrapper">
        <div class="cover flex-box-column">
            <div class="hd">
                <van-nav-bar left-arrow title="分销商详情"  @click-left="back"></van-nav-bar>
            </div>
            <div class="bd">
                <van-cell-group>
                    <van-cell title="公司名称" :value="auditData.name" />
                    <van-cell title="负责人"   :value="auditData.managerName"/>
                    <van-cell title="负责人号码" :value="auditData.managerPhone" />
                    <van-cell title="公司地址" :value="auditData.address"/>
                </van-cell-group>  
                <van-cell-group class="m-t-10">
                    <van-cell title="营业执照">
                        <div class="order-images-cover"  v-if="auditData.businessLicensePic">
                            <div class="order-images-show">
                                <div class="order-image" @click="fktImagePreviewShowOpen({item:auditData.businessLicensePic})">
                                    <img :src="auditData.businessLicensePic | $imageUrl" alt="">
                                </div>
                            </div>
                        </div>
                    </van-cell>
                </van-cell-group>
                <van-cell-group class="m-t-10">
                    <van-cell title="渠道对接人"></van-cell>
                    <template v-for="(item,index) in channelInfo">
                        <van-cell :title="item.name" :label="item.isDefault ? '默认对接人' : ''" :url="'tel:'+item.phone" :value="item.phone" is-link :key="index"></van-cell>
                    </template>
                    <p v-if="channelInfo.length == 0" class="color-999 p-all-15 center text-center">暂无渠道对接人</p>
                </van-cell-group>
            </div>
        </div>
        <!-- 图片预览 -->
        <van-image-preview 
        v-model="fkyImagePreviewShow" 
        :images="fkyImagePreviewList" 
        :start-position="fkyImagePreviewIndex"
        :max-zoom="3"></van-image-preview>
   </div>
</template>

<script>
import backMixin from "@/mixins/back";
import {pxToVw} from "@/utils";
import imagePreviewMixin from "@/mixins/imagePreview";
import api from "@/api"
export default {

    mixins:[backMixin,imagePreviewMixin],

    data(){

        return{

            auditData:{},
            
            disabled:false,

            //当前选择的参数

            form:{

                status:'',

                remark:''

            }


        }

    },

    computed:{

        btnDisabled(){

            return this.form.status == 0 && !this.form.status

        },

        channelInfo(){

            return this.auditData?.chanelInfo || [];

        }


    },

    methods:{

        getVw(px){

            return pxToVw(px) + 'vw';

        },

        //请求楼盘数据

        updateData(){

            this.$toast.loading({

                message:'正在加载...',

                duration:0,

                overlay:true

            });

            api.getSubdistributorDetail({

                options:{

                    id:this.$route.params?.id

                },

                success:(res)=>{

                    if(res.type == 200){

                        this.auditData = res.data;

                        this.disabled=false;
                        
                    }else{

                        this.$toast(res.msg || '获取审核详情数据失败，请稍后重试');

                        this.disabled=true;

                    }
                    
                },

                error:(err)=>{

                    console.log(err);

                    this.$toast('获取审核详情数据失败，加载异常，请稍后重试，'+err);

                    this.disabled=true;

                },

                complete: ()=>{

                    this.$toast.clear();
                    
                }

            })

        },







        //初始化方法

        init(){

            this.updateData();

        }

    },


    mounted(){

        this.$fky_callback(()=>{

            this.init();

        },200)

    }

}
</script>

<style scoped lang="less">
.audit-detail-wrapper{

    &,.cover{

        height:100%

    }

    .bd{

        flex:1;

        height:0;

        overflow: hidden;

        overflow-y:auto;

        background: #f7f8fa;

        .van-cell__title{

            flex:0 1 auto;

            padding-right:20px;

        }

        .van-cell__value{

            width: 0;

        }

        .form-item{

            margin-top:10px;

            margin-bottom:16px;

        }

        .thumb-cover{

            width: 100px;

            height:80px;

            img{

                width: 100%;

                height:100%;

            }

        }

        .form-item_title{

            padding: 6px 16px 16px;

            font-size:14px;

            color:rgba(69, 90, 100, 0.6);

        }

    }

}
</style>